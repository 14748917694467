import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import style from "../css/Footer.module.css";
import logo from "../img/component/footer_logo.billions.svg";
import naverPost from "../img/component/naverpost.png";
import instaPost from "../img/component/instapost.png"
import youtubePost from "../img/component/youtubepost.svg"
import facebookPost from "../img/component/facebookpost.svg"
import arrow from "../img/component/select_arrow.svg";

export default function Footer({isEn, setIsEn, animated, setAnimated}) {
    const contents = [
        { id: 1, content: "Company", isHover: false, link: "company" },
        { id: 2, content: "Production", isHover: true, hoverContent: [["Drama", "Producer"], ["Movie"], ["Album/Concert"]], link: "production"},
        { id: 3, content: "Artist", isHover: false, link: "artist" },
        { id: 4, content: "Musician", isHover: false, link: "musician" },
        { id: 5, content: "News", isHover: true, hoverContent: [["News"], ["Notice"]], link: "news" },
        { id: 6, content: "Audition", isHover: false, link: "audition" }
    ];
    const selectContents = [
        { id: 1, content: "Kyungnam pharm", link: "https://www.kyungnampharm.com:6519/index.asp" },
        { id: 2, content: "Nomoney market", link: "https://nomoney.market/" },
        { id: 3, content: "Lemona", link: "http://www.lemona.co.kr/" }
    ]
    const [selectShow, setSelectShow] = useState(false);
    const isActive = useRef();

    function openWindow(e, url) {
        e.stopPropagation();
        window.open(url)
    }

    function setAnimate() {
        if(isActive.current.className !== "active") {
            setAnimated(Math.random());
        }
    }

    return(
        <div className={ style.wrap }>
            <div className={ style.topContainer }>
                <div className={ style.topLogo }>
                    <Link ref={ isActive } to="" onClick={ setAnimate }>
                        <img src={ logo } alt="footerLogo"></img>
                    </Link>
                </div>
                <div className={ style.topLink }>
                    {
                        contents.map((content) => (
                            content.isHover ? 
                            <div key={ content.id } className={ style.topLinkItem }>
                                <span>{ content.content }</span>
                                <div className={ style.hoverItem }>
                                    {
                                        content.hoverContent.map((hoverContent, idx) =>(
                                            hoverContent.length > 1 ?
                                            <div key={idx} className={style.doubleContent}>
                                                <span>{hoverContent[0]}</span>
                                                {
                                                    hoverContent.map((data) =>(
                                                        <Link key={ idx + data } to={ content.link  + "?column=" + data}>
                                                            <span>
                                                                - {data}
                                                            </span>
                                                        </Link>
                                                    ))
                                                }
                                            </div>
                                            :
                                            <Link key={ idx } to={ content.link  + "?column=" + hoverContent}>
                                                <span key={ idx }>
                                                    { hoverContent }
                                                </span>
                                            </Link>
                                        ))
                                    }
                                </div>
                            </div>
                            :
                            <div key={ content.id } className={ style.topLinkItem }>
                                <Link to={ content.link }>
                                    <span>{ content.content }</span>
                                </Link>
                            </div>
                        ))
                    }
                </div>
                <hr className={ style.topHr }></hr>
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.bottomInfo }>
                    {
                    isEn ?
                    <span>
                        Corporation BILLIONS<br/>3F, 648, Samseong-ro, Gangnam-gu, Seoul, Republic of Korea<br/>COPYRIGHT (C) 2024 BILLIONS OK. ALL RIGHT RESERVED.
                    </span>
                    :
                    <span>
                        주식회사 빌리언스<br/>서울특별시 강남구 삼성로 648, 3층 (판타지오빌딩)<br/>대표자 : 박종진, 심화석 | 사업자등록번호 : 315-81-00385(본점) 637-85-02374(지점)<br/>COPYRIGHT (C) 2024 BILLIONS OK. ALL RIGHT RESERVED.
                    </span>
                    }
                </div>
                <div className={ style.bottomSelect }>
                    <div className={ style.selectBoxWrap }>
                        <div className={ selectShow ? `${style.selectBoxContent} ${style.open}` : style.selectBoxContent }>
                            {
                                selectContents.map((selectContent) => (
                                    <span key={ selectContent.id } onClick={ () => window.open(selectContent.link) }>
                                        { selectContent.content }
                                    </span>
                                ))
                            }
                            <hr className={ style.selectBoxHr }></hr>
                        </div>
                    </div>
                    <div className={ style.selectBox } onClick={ () => setSelectShow(!selectShow) }>
                        <div className={ style.postContainer }>
                            <img src={instaPost} alt="instaPost" onClick={ (e) => openWindow(e, "https://www.instagram.com/billions_ent_") }></img>
                            <img src={youtubePost} alt="youtubePost" onClick={ (e) => openWindow(e, "https://www.youtube.com/@BILLIONS_official") }></img>
                            <img src={facebookPost} alt="facebookPost" onClick={ (e) => openWindow(e, "https://www.facebook.com/bladeent.official/") }></img>
                            <img src={naverPost} alt="naverPost" onClick={ (e) => openWindow(e, "https://m.post.naver.com/my.naver?memberNo=57843431") }></img>
                        </div>
                        <span>Family site</span>
                        <img src={ arrow } className={ selectShow ? style.show : "" } alt="selectArrow"></img>
                    </div>
                </div>
            </div>
        </div>
    )
}