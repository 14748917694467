import { useState, useEffect } from "react";
import axios from "axios";
import style from "../css/Pages2.module.css";
import adminStyle from "../css/AdminModal.module.css";
import MusicianItem from "../PageComponents/MusicianItem";
import Portal from "../Components/Portal";
import MusicianModal from "../PageComponents/MusicianModal";
import { Transition } from "react-transition-group";
import NavigationBox from "../PageComponents/NavigationBox";
import AdminModal from "../PageComponents/AdminModal";
import SEOMeta from "../PageComponents/SEOMeta";

export default function Musician({ isEn, setSeoData, isAdmin, showAdmin, setShowAdmin, field, setField, render, setRender }) {
    const [getData, setData] = useState([]);
    const [musicianData, setMusicianData] = useState();
    const [showMusician, setShowMusician] = useState(false);
    const [musicianIdx, setMusicianIdx] = useState();

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER + '/api/data/musician')
            .then((Response) => {
                setData(Response.data.data);
                if(Response.data.data.length !== 0) {
                    setMusicianIdx(Response.data.data[0].id)
                }
            })
            .catch((Error) => {
                console.log(Error)
            })
    }, [render])

    useEffect(() => {
        if(musicianIdx !== undefined) {
            axios.get(process.env.REACT_APP_SERVER + '/api/data/musician/' + musicianIdx)
                .then((Response) => {
                    setMusicianData(Response.data.data[0]);
                })
                .catch((Error) => {
                    console.log(Error)
                })
        }
    }, [musicianIdx])

    return(
        <div className={ style.wrap }>
            <SEOMeta title="Musician ㅣ 빌리언스" description="빌리언스 뮤지션 프로필 제공" url="https://thebillions.co.kr/musician"></SEOMeta>
            <div className={ style.container }>
                <NavigationBox page="Musician" />
                <div className={ style.musicianContainer }>
                    {
                        Array.isArray(getData) && getData.length !== 0 ?
                        <MusicianItem data={ getData } isEn={ isEn } setShowMusician={ setShowMusician } setMusicianIdx={ setMusicianIdx } />
                        :
                        <div className={style.musicianNodataContainer}>
                            <span className={ style.productionNoData }>Comming Soon!</span>
                        </div>
                    }
                </div>
                {
                    isAdmin &&
                    <div className={ adminStyle.introArtistContainer }>
                        <div className={ adminStyle.actionButton } onClick={ () => { setShowAdmin(true); setField("musician") } }>
                            <span>프로필 관리</span>
                        </div>
                    </div>
                }
            </div>
            <Portal>
                <Transition unmountOnExit in={ showMusician } timeout={400}>
                    { <MusicianModal setShowMusician={ setShowMusician } showMusician={ showMusician } musicianData={ musicianData } isEn={ isEn }/> }
                </Transition>
            </Portal>
            {
                (field === "musician") &&
                <Portal>
                    <Transition unmountOnExit in={ showAdmin } timeout={0}>                      
                        <AdminModal showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setRender={ setRender } />
                    </Transition>
                </Portal>
            }
        </div>
    )
}