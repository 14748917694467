import { useEffect, useState } from "react"
import style from "../css/Main.module.css";
import adminStyle from "../css/AdminModal.module.css";
import mainArrow from "../img/component/main_img_arrow.svg";
import explainLogo from "../img/component/main_explain_img.billions.svg";
import explainArrow from "../img/component/main_explain_button_arrow.png";
import spectrum1 from "../img/component/main_spectrum1.svg";
import spectrum2 from "../img/component/main_spectrum2.svg";
import spectrum3 from "../img/component/main_spectrum3.svg";
import business_m from "../img/component/main_business_m.svg";
import business_d from "../img/component/main_business_d.svg";
import business_a from "../img/component/main_business_a.svg";
import explain4Logo from "../img/component/main_explain4_logo.billions.svg";
import Portal from "../Components/Portal";
import { Transition } from "react-transition-group";
import AdminModal from "../PageComponents/AdminModal";
import Popup from "../Components/PopUp";
import Popup2 from "../Components/PopUp2";
import { NavLink } from "react-router-dom";
import axios from "axios";
import SEOMeta from "../PageComponents/SEOMeta";

export default function Main({isEn, isAdmin, showAdmin, setShowAdmin, field, setField, render, setRender}) {
    const [backImage, setBackImage] = useState("");
    const [popup, setPopup] = useState(false);
    const VISIT_NOW = new Date().getDate();
    const VISIT_BEFORE = Number(localStorage.getItem('Visit_Cookie_ent'));

    const [popup2, setPopup2] = useState(false);
    const VISIT_NOW2 = new Date().getDate();
    const VISIT_BEFORE2 = Number(localStorage.getItem('Visit_Cookie_ent2'));

    useEffect(() => {
        if(VISIT_BEFORE === null) {
            setPopup(false)
        } else {
            if(VISIT_BEFORE === VISIT_NOW) {
                setPopup(false)
            } else {
                setPopup(true)
            }
        }
    }, [VISIT_BEFORE])

    useEffect(() => {
        if(VISIT_BEFORE2 === null) {
            setPopup2(false)
        } else {
            if(VISIT_BEFORE2 === VISIT_NOW2) {
                setPopup2(false);
            } else {
                setPopup2(true)
            }
        }
    }, [VISIT_BEFORE2])

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER + '/api/data/main')
            .then((Response) => {
                setBackImage(Response.data.data[0].imgUrl)
            })
            .catch((Error) => {
                console.log(Error)
            })
    }, [render])

    return(
        <div className={ style.wrap }>
            <SEOMeta title="BILLIONS" description="아티스트 매니지먼트, 드라마/음반제작, 글로벌 공연사업 등 종합 엔터테인먼트 그룹" url="https://thebillions.co.kr"></SEOMeta>
            {/*{popup && (*/}
            {/*    <div id={style.modalBackdrop}>*/}
            {/*        {popup && <Popup setPopup={setPopup} popup={popup}></Popup>}*/}
            {/*    </div>*/}
            {/*)}*/}
            <div className={ style.imgContainer }>
                <div className={ style.imgBack }>
                    <img src={ backImage } alt="background"></img>
                    <div className={ style.contentContainer }>
                        <div className={ style.content }>
                            {
                                isAdmin && <div className={ `${adminStyle.actionButton} ${adminStyle.introMainBtn}` } onClick={ () => { setShowAdmin(true); setField("main") } }>이미지 수정</div>
                            }
                            <span>WE TALK.</span>
                            <span>IT BECOMES US</span>
                            <span>THROUGH</span>
                            <span>THE STORY</span>
                        </div>
                        <div className={ style.arrow }>
                            <img src={ mainArrow } alt="arrow"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ style.explainContainer}>
                <div className={ style.explain }>
                    <div className={ `${style.explainItem} ${style.hover}` }>
                        <img id={ style.explainLogo } src={ explain4Logo } alt="explainLogo"></img>
                    </div>
                    <div className={ style.explainItem }>
                        <div className={ style.redDot }></div>
                        {
                            isEn ?
                            <span>BILLIONS is moving forward as a global entertainment group through artist management, drama/record production, and performance business.</span>
                            :
                            <span>BILLIONS는 아티스트 매니지먼트, 드라마/음반제작, 글로벌 공연 사업 등을 통해 세계 최고의 엔터테인먼트 그룹으로 나아가고 있습니다.</span>
                        }
                    </div>
                    <div className={ style.explainItem }>
                        <NavLink to="/company">
                            <div className={ style.explainButton }>
                                <span>More</span>
                                <img src={ explainArrow } alt="arrow"></img>
                            </div>
                        </NavLink>
                    </div>
                </div>
                <div className={ style.explain }>
                    <div className={ style.explainItem2 }>
                        <div className={ style.redDot2 }></div>
                        <span>OUR BUSINESS</span>
                    </div>
                    <div className={ style.explainItem2 }>
                        <div className={ style.spectrumContainer }>
                            <div className={ style.spectrumItem }>
                                <img className={ style.spectrumIcon } src={ business_m} alt="icon"></img>
                                <img src={ spectrum1 } alt="spectrum"></img>
                            </div>
                            <div className={ style.spectrumItem }>
                            <img className={ style.spectrumIcon } src={ business_d} alt="icon"></img>
                                <img src={ spectrum2 } alt="spectrum"></img>
                            </div>
                            <div className={ style.spectrumItem }>
                                <img className={ style.spectrumIcon } src={ business_a} alt="icon"></img>
                                <img src={ spectrum3 } alt="spectrum"></img>
                            </div>
                        </div>
                        <div className={ style.spectrumContentContainer}>
                            <div className={ style.spectrumContentItem }>
                                <div className={ style.contentItemTheme }>
                                    <span>{ isEn ? "Management" : "매니지먼트" }</span>
                                </div>
                                <div className={ style.contentItemcontent }>
                                    <span>{ isEn ? "- Encouraging artists to showcase their talents." : "- 아티스트의 특성에 맞춰 개개인의 재능을 보여줄 수 있는 활동 제고." }</span>
                                    <span>{ isEn ? "- Establishment of a professional care system such as PR, management, advertisement and scenario analysis." : "- 매니지먼트, 홍보, 광고, 시나리오 분석 등 다양한 분야에서 아티스트 각자의 개성에 맞춘 전문 케어 시스템 구축."} </span>
                                </div>
                            </div>
                            <div className={ style.spectrumContentItem }>
                                <div className={ style.contentItemTheme }>
                                    <span>{ isEn ? "Drama" : "드라마" }</span>
                                </div>
                                <div className={ style.contentItemcontent }>
                                    <span>{ isEn ? "- Building a business with fresh and original planning." : "- 감독&작가, 아티스트와 함께 신선하고 독창적인 기획으로 사업 구축." }</span>
                                    <span>{ isEn ? "- Production of differentiated video contents in various genres such as comedy, melodrama, and sitcom." : "- 로맨틱 코미디, 멜로, 사극, 시트콤 등 독특한 소재와 다양한 장르를 다룸으로써 차별화 된 영상콘텐츠 제작." }</span>
                                </div>
                            </div>
                            <div className={ style.spectrumContentItem }>
                                <div className={ style.contentItemTheme }>
                                    <span>{ isEn ? "Album & Concert" : "앨범 & 콘서트" }</span>
                                </div>
                                <div className={ style.contentItemcontent }>
                                    <span>{ isEn ? "- Various content planning and production through" : "- 역량있는 아티스트들의 발굴과 육성을 통한 다양한 음악 콘텐츠 기획 및 제작." }</span>
                                    <span>{ isEn ? "- managementExpansion of global distribution network through music and album release and marketing" : "- 음원 및 음반 출시, 마케팅, 부가사업 등을 제공함으로써 전 세계를 하나로 연결할 수 있는 유통망 확장" }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={ style.explain }>
                    <div className={ style.explainItem4 }>
                        <img src={ explain4Logo } alt="explainItem4Logo"></img>
                    </div>
                    <div className={ style.explainItem4 }>
                        <span className={ style.explainItem4Info }>
                            648 3F, Samseong-ro, Gangnam-gu, Seoul, Republic of Korea<br/><br/>T. 02-2203-3835<br/>E. billions@thebillions.co.kr
                        </span>
                    </div>
                </div>
            </div>
            {
                field === "main" &&
                <Portal>
                    <Transition unmountOnExit in={ showAdmin } timeout={0}>
                        <AdminModal showAdmin={ showAdmin } setShowAdmin={ setShowAdmin } field={ field } setRender={ setRender } />
                    </Transition>
                </Portal>
            }
        </div>
    )
}